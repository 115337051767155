import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _d490c5f4 = () => interopDefault(import('../pages/articeListMsg/index.vue' /* webpackChunkName: "pages/articeListMsg/index" */))
const _16cbf08a = () => interopDefault(import('../pages/articelList.vue' /* webpackChunkName: "pages/articelList" */))
const _ad9d99b6 = () => interopDefault(import('../pages/articelList.vue/index.vue' /* webpackChunkName: "pages/articelList.vue/index" */))
const _04df6525 = () => interopDefault(import('../pages/bookDetail/index.vue' /* webpackChunkName: "pages/bookDetail/index" */))
const _40aab90a = () => interopDefault(import('../pages/bookPreview/index.vue' /* webpackChunkName: "pages/bookPreview/index" */))
const _4086141c = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _5dad5fa8 = () => interopDefault(import('../pages/buyCourse/index.vue' /* webpackChunkName: "pages/buyCourse/index" */))
const _63774aba = () => interopDefault(import('../pages/class/index.vue' /* webpackChunkName: "pages/class/index" */))
const _6f495b9f = () => interopDefault(import('../pages/columnist/index.vue' /* webpackChunkName: "pages/columnist/index" */))
const _66f85dec = () => interopDefault(import('../pages/consultListYxj/index.vue' /* webpackChunkName: "pages/consultListYxj/index" */))
const _0879d812 = () => interopDefault(import('../pages/courseDetails/index.vue' /* webpackChunkName: "pages/courseDetails/index" */))
const _089e229d = () => interopDefault(import('../pages/courseEmpty/index.vue' /* webpackChunkName: "pages/courseEmpty/index" */))
const _b41b20ea = () => interopDefault(import('../pages/detailPage/index.vue' /* webpackChunkName: "pages/detailPage/index" */))
const _07b2a8da = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _acdcefa0 = () => interopDefault(import('../pages/ebooks/index.vue' /* webpackChunkName: "pages/ebooks/index" */))
const _5eccfe6f = () => interopDefault(import('../pages/epidemicInformation/index.vue' /* webpackChunkName: "pages/epidemicInformation/index" */))
const _02abd727 = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _4bba4212 = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _899515b0 = () => interopDefault(import('../pages/liveDetail/index.vue' /* webpackChunkName: "pages/liveDetail/index" */))
const _b7d48a7a = () => interopDefault(import('../pages/newClass/index.vue' /* webpackChunkName: "pages/newClass/index" */))
const _108e5760 = () => interopDefault(import('../pages/novelCoronavirus/index.vue' /* webpackChunkName: "pages/novelCoronavirus/index" */))
const _4fca43c1 = () => interopDefault(import('../pages/novelCoronavirusDetail/index.vue' /* webpackChunkName: "pages/novelCoronavirusDetail/index" */))
const _05daad05 = () => interopDefault(import('../pages/payDetails/index.vue' /* webpackChunkName: "pages/payDetails/index" */))
const _20e57d86 = () => interopDefault(import('../pages/paySuccess/index.vue' /* webpackChunkName: "pages/paySuccess/index" */))
const _49b5c69a = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _6cdbadd4 = () => interopDefault(import('../pages/self.vue' /* webpackChunkName: "pages/self" */))
const _d5c8f552 = () => interopDefault(import('../pages/self/index.vue' /* webpackChunkName: "pages/self/index" */))
const _326087b3 = () => interopDefault(import('../pages/self/auth.vue' /* webpackChunkName: "pages/self/auth" */))
const _3a2dac3a = () => interopDefault(import('../pages/self/myCollect.vue' /* webpackChunkName: "pages/self/myCollect" */))
const _6edde9f2 = () => interopDefault(import('../pages/self/myCourse.vue' /* webpackChunkName: "pages/self/myCourse" */))
const _313c1b72 = () => interopDefault(import('../pages/self/myOrder.vue' /* webpackChunkName: "pages/self/myOrder" */))
const _46d6b982 = () => interopDefault(import('../pages/self/pwd.vue' /* webpackChunkName: "pages/self/pwd" */))
const _7d4e5eb8 = () => interopDefault(import('../pages/special/index.vue' /* webpackChunkName: "pages/special/index" */))
const _253567e5 = () => interopDefault(import('../pages/survey/index.vue' /* webpackChunkName: "pages/survey/index" */))
const _72885534 = () => interopDefault(import('../pages/tagPage/index.vue' /* webpackChunkName: "pages/tagPage/index" */))
const _0ffef2d4 = () => interopDefault(import('../pages/yxj/index.vue' /* webpackChunkName: "pages/yxj/index" */))
const _c70a92aa = () => interopDefault(import('../pages/about/aboutMe.vue' /* webpackChunkName: "pages/about/aboutMe" */))
const _6b20d746 = () => interopDefault(import('../pages/about/contact.vue' /* webpackChunkName: "pages/about/contact" */))
const _7b663aa4 = () => interopDefault(import('../pages/about/privacy.vue' /* webpackChunkName: "pages/about/privacy" */))
const _bd5886a6 = () => interopDefault(import('../pages/about/terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _9a66aebe = () => interopDefault(import('../pages/details/live.vue' /* webpackChunkName: "pages/details/live" */))
const _86904bd4 = () => interopDefault(import('../pages/details/video.vue' /* webpackChunkName: "pages/details/video" */))
const _a9f00c82 = () => interopDefault(import('../pages/ebooks/bookshelf.vue' /* webpackChunkName: "pages/ebooks/bookshelf" */))
const _45800606 = () => interopDefault(import('../pages/guide/constitutorFilter.vue' /* webpackChunkName: "pages/guide/constitutorFilter" */))
const _7ccd2d57 = () => interopDefault(import('../pages/guide/details.vue' /* webpackChunkName: "pages/guide/details" */))
const _79db26a2 = () => interopDefault(import('../pages/guide/filterPage.vue' /* webpackChunkName: "pages/guide/filterPage" */))
const _7594dac4 = () => interopDefault(import('../pages/guide/searcPage.vue' /* webpackChunkName: "pages/guide/searcPage" */))
const _79a6699e = () => interopDefault(import('../pages/bookDetail/components/directory.vue' /* webpackChunkName: "pages/bookDetail/components/directory" */))
const _26a21738 = () => interopDefault(import('../pages/bookDetail/components/directoryItem.vue' /* webpackChunkName: "pages/bookDetail/components/directoryItem" */))
const _66bed129 = () => interopDefault(import('../pages/bookDetail/components/fontSizeProgress.vue' /* webpackChunkName: "pages/bookDetail/components/fontSizeProgress" */))
const _3159e3ea = () => interopDefault(import('../pages/bookDetail/components/progressBar.vue' /* webpackChunkName: "pages/bookDetail/components/progressBar" */))
const _6d5ae0a2 = () => interopDefault(import('../pages/courseDetails/components/coueseCatalog.vue' /* webpackChunkName: "pages/courseDetails/components/coueseCatalog" */))
const _c1efce0a = () => interopDefault(import('../pages/courseDetails/components/courseComment.vue' /* webpackChunkName: "pages/courseDetails/components/courseComment" */))
const _a2c2fa8a = () => interopDefault(import('../pages/courseDetails/components/courseIntroduce.vue' /* webpackChunkName: "pages/courseDetails/components/courseIntroduce" */))
const _06701bde = () => interopDefault(import('../pages/courseDetails/components/courseQuesstion.vue' /* webpackChunkName: "pages/courseDetails/components/courseQuesstion" */))
const _76ba81ae = () => interopDefault(import('../pages/courseDetails/components/replyComment.vue' /* webpackChunkName: "pages/courseDetails/components/replyComment" */))
const _61422164 = () => interopDefault(import('../pages/courseDetails/components/replyOthers.vue' /* webpackChunkName: "pages/courseDetails/components/replyOthers" */))
const _2834f968 = () => interopDefault(import('../pages/ebooks/components/bookClass.vue' /* webpackChunkName: "pages/ebooks/components/bookClass" */))
const _476fc7d3 = () => interopDefault(import('../pages/ebooks/components/bookItem.vue' /* webpackChunkName: "pages/ebooks/components/bookItem" */))
const _68085a15 = () => interopDefault(import('../pages/ebooks/components/bookPopover.vue' /* webpackChunkName: "pages/ebooks/components/bookPopover" */))
const _bee68312 = () => interopDefault(import('../pages/ebooks/components/myBooks.vue' /* webpackChunkName: "pages/ebooks/components/myBooks" */))
const _066749c2 = () => interopDefault(import('../pages/search/components/articleItem.vue' /* webpackChunkName: "pages/search/components/articleItem" */))
const _df1089d4 = () => interopDefault(import('../pages/search/components/bookItem.vue' /* webpackChunkName: "pages/search/components/bookItem" */))
const _4aae5dc8 = () => interopDefault(import('../pages/search/components/courseItem.vue' /* webpackChunkName: "pages/search/components/courseItem" */))
const _049c7ab6 = () => interopDefault(import('../pages/search/components/guideItem.vue' /* webpackChunkName: "pages/search/components/guideItem" */))
const _28b8cb3b = () => interopDefault(import('../pages/search/components/mainSearch.vue' /* webpackChunkName: "pages/search/components/mainSearch" */))
const _32fedbdf = () => interopDefault(import('../pages/search/components/searchResult.vue' /* webpackChunkName: "pages/search/components/searchResult" */))
const _751cd7f4 = () => interopDefault(import('../pages/search/components/searchTitle.vue' /* webpackChunkName: "pages/search/components/searchTitle" */))
const _79854d55 = () => interopDefault(import('../pages/search/components/subjectItem.vue' /* webpackChunkName: "pages/search/components/subjectItem" */))
const _a5467686 = () => interopDefault(import('../pages/survey/components/newsList.vue' /* webpackChunkName: "pages/survey/components/newsList" */))
const _65e3ae7a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
            return {
                x: 0,
                y: 0,
            }
        }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/articeListMsg",
      component: _d490c5f4,
      name: "articeListMsg"
    }, {
      path: "/articelList",
      component: _16cbf08a,
      name: "articelList"
    }, {
      path: "/articelList.vue",
      component: _ad9d99b6,
      name: "articelList.vue"
    }, {
      path: "/bookDetail",
      component: _04df6525,
      name: "bookDetail"
    }, {
      path: "/bookPreview",
      component: _40aab90a,
      name: "bookPreview"
    }, {
      path: "/brand",
      component: _4086141c,
      name: "brand"
    }, {
      path: "/buyCourse",
      component: _5dad5fa8,
      name: "buyCourse"
    }, {
      path: "/class",
      component: _63774aba,
      name: "class"
    }, {
      path: "/columnist",
      component: _6f495b9f,
      name: "columnist"
    }, {
      path: "/consultListYxj",
      component: _66f85dec,
      name: "consultListYxj"
    }, {
      path: "/courseDetails",
      component: _0879d812,
      name: "courseDetails"
    }, {
      path: "/courseEmpty",
      component: _089e229d,
      name: "courseEmpty"
    }, {
      path: "/detailPage",
      component: _b41b20ea,
      name: "detailPage"
    }, {
      path: "/download",
      component: _07b2a8da,
      name: "download"
    }, {
      path: "/ebooks",
      component: _acdcefa0,
      name: "ebooks"
    }, {
      path: "/epidemicInformation",
      component: _5eccfe6f,
      name: "epidemicInformation"
    }, {
      path: "/guide",
      component: _02abd727,
      name: "guide"
    }, {
      path: "/live",
      component: _4bba4212,
      name: "live"
    }, {
      path: "/liveDetail",
      component: _899515b0,
      name: "liveDetail"
    }, {
      path: "/newClass",
      component: _b7d48a7a,
      name: "newClass"
    }, {
      path: "/novelCoronavirus",
      component: _108e5760,
      name: "novelCoronavirus"
    }, {
      path: "/novelCoronavirusDetail",
      component: _4fca43c1,
      name: "novelCoronavirusDetail"
    }, {
      path: "/payDetails",
      component: _05daad05,
      name: "payDetails"
    }, {
      path: "/paySuccess",
      component: _20e57d86,
      name: "paySuccess"
    }, {
      path: "/search",
      component: _49b5c69a,
      name: "search"
    }, {
      path: "/self",
      component: _6cdbadd4,
      children: [{
        path: "",
        component: _d5c8f552,
        name: "self"
      }, {
        path: "auth",
        component: _326087b3,
        name: "self-auth"
      }, {
        path: "myCollect",
        component: _3a2dac3a,
        name: "self-myCollect"
      }, {
        path: "myCourse",
        component: _6edde9f2,
        name: "self-myCourse"
      }, {
        path: "myOrder",
        component: _313c1b72,
        name: "self-myOrder"
      }, {
        path: "pwd",
        component: _46d6b982,
        name: "self-pwd"
      }]
    }, {
      path: "/special",
      component: _7d4e5eb8,
      name: "special"
    }, {
      path: "/survey",
      component: _253567e5,
      name: "survey"
    }, {
      path: "/tagPage",
      component: _72885534,
      name: "tagPage"
    }, {
      path: "/yxj",
      component: _0ffef2d4,
      name: "yxj"
    }, {
      path: "/about/aboutMe",
      component: _c70a92aa,
      name: "about-aboutMe"
    }, {
      path: "/about/contact",
      component: _6b20d746,
      name: "about-contact"
    }, {
      path: "/about/privacy",
      component: _7b663aa4,
      name: "about-privacy"
    }, {
      path: "/about/terms",
      component: _bd5886a6,
      name: "about-terms"
    }, {
      path: "/details/live",
      component: _9a66aebe,
      name: "details-live"
    }, {
      path: "/details/video",
      component: _86904bd4,
      name: "details-video"
    }, {
      path: "/ebooks/bookshelf",
      component: _a9f00c82,
      name: "ebooks-bookshelf"
    }, {
      path: "/guide/constitutorFilter",
      component: _45800606,
      name: "guide-constitutorFilter"
    }, {
      path: "/guide/details",
      component: _7ccd2d57,
      name: "guide-details"
    }, {
      path: "/guide/filterPage",
      component: _79db26a2,
      name: "guide-filterPage"
    }, {
      path: "/guide/searcPage",
      component: _7594dac4,
      name: "guide-searcPage"
    }, {
      path: "/bookDetail/components/directory",
      component: _79a6699e,
      name: "bookDetail-components-directory"
    }, {
      path: "/bookDetail/components/directoryItem",
      component: _26a21738,
      name: "bookDetail-components-directoryItem"
    }, {
      path: "/bookDetail/components/fontSizeProgress",
      component: _66bed129,
      name: "bookDetail-components-fontSizeProgress"
    }, {
      path: "/bookDetail/components/progressBar",
      component: _3159e3ea,
      name: "bookDetail-components-progressBar"
    }, {
      path: "/courseDetails/components/coueseCatalog",
      component: _6d5ae0a2,
      name: "courseDetails-components-coueseCatalog"
    }, {
      path: "/courseDetails/components/courseComment",
      component: _c1efce0a,
      name: "courseDetails-components-courseComment"
    }, {
      path: "/courseDetails/components/courseIntroduce",
      component: _a2c2fa8a,
      name: "courseDetails-components-courseIntroduce"
    }, {
      path: "/courseDetails/components/courseQuesstion",
      component: _06701bde,
      name: "courseDetails-components-courseQuesstion"
    }, {
      path: "/courseDetails/components/replyComment",
      component: _76ba81ae,
      name: "courseDetails-components-replyComment"
    }, {
      path: "/courseDetails/components/replyOthers",
      component: _61422164,
      name: "courseDetails-components-replyOthers"
    }, {
      path: "/ebooks/components/bookClass",
      component: _2834f968,
      name: "ebooks-components-bookClass"
    }, {
      path: "/ebooks/components/bookItem",
      component: _476fc7d3,
      name: "ebooks-components-bookItem"
    }, {
      path: "/ebooks/components/bookPopover",
      component: _68085a15,
      name: "ebooks-components-bookPopover"
    }, {
      path: "/ebooks/components/myBooks",
      component: _bee68312,
      name: "ebooks-components-myBooks"
    }, {
      path: "/search/components/articleItem",
      component: _066749c2,
      name: "search-components-articleItem"
    }, {
      path: "/search/components/bookItem",
      component: _df1089d4,
      name: "search-components-bookItem"
    }, {
      path: "/search/components/courseItem",
      component: _4aae5dc8,
      name: "search-components-courseItem"
    }, {
      path: "/search/components/guideItem",
      component: _049c7ab6,
      name: "search-components-guideItem"
    }, {
      path: "/search/components/mainSearch",
      component: _28b8cb3b,
      name: "search-components-mainSearch"
    }, {
      path: "/search/components/searchResult",
      component: _32fedbdf,
      name: "search-components-searchResult"
    }, {
      path: "/search/components/searchTitle",
      component: _751cd7f4,
      name: "search-components-searchTitle"
    }, {
      path: "/search/components/subjectItem",
      component: _79854d55,
      name: "search-components-subjectItem"
    }, {
      path: "/survey/components/newsList",
      component: _a5467686,
      name: "survey-components-newsList"
    }, {
      path: "/",
      component: _65e3ae7a,
      name: "index"
    }],

    fallback: false
  })
}
